import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | Solarvus Fitness
			</title>
			<meta name={"description"} content={"Повысьте свой уровень"} />
			<meta property={"og:title"} content={"Наши услуги | Solarvus Fitness"} />
			<meta property={"og:description"} content={"Повысьте свой уровень"} />
			<meta property={"og:image"} content={"https://solarvus.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solarvus.com/img/57322.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarvus.com/img/57322.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarvus.com/img/57322.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarvus.com/img/57322.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarvus.com/img/57322.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarvus.com/img/57322.png"} />
			<meta name={"msapplication-TileColor"} content={"https://solarvus.com/img/57322.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Фитнес-услуги высшего уровня
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			В Solarvus Fitness мы предлагаем полный набор услуг, призванных удовлетворить все аспекты вашего фитнес-пути. От передовых фитнес-программ до индивидуального коучинга - каждая услуга создана для того, чтобы помочь вам достичь наилучшего состояния здоровья.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Персональные тренировки: Индивидуальные планы тренировок для достижения ваших уникальных фитнес-целей под руководством сертифицированных тренеров.
					<br />
					<br />
					Индивидуальные тренировки: Конкретные упражнения, разработанные с учетом ваших потребностей.
					<br />
					<br />
					Индивидуальные тренировки: персональное внимание для обеспечения правильной техники и прогресса.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://solarvus.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://solarvus.com/img/8.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Групповые занятия по фитнесу: Заряжайтесь энергией во время тренировок в мотивирующей групповой обстановке.
					<br />
					<br />
					Йога и пилатес: Сосредоточьтесь на гибкости, равновесии и основной силе.
					<br />
					<br />
					Кардио-классы: Высокоэнергетические занятия, которые улучшают здоровье сердца и сжигают калории.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Оздоровительные программы: Холистические подходы для поддержания общего здоровья и благополучия.
					<br />
					<br />
					Консультации по питанию: Экспертные советы по питанию, дополняющие ваш фитнес-режим.
					<br />
					<br />
					Семинары по управлению стрессом: Техники для укрепления психического здоровья и повышения устойчивости.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://solarvus.com/img/9.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});